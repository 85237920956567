import { NextRouter } from 'next/router'
import { getAuthWindowParams } from '~/lib/auth'
import { AuthToken } from '~/lib/AuthToken'

interface Props {
  token_url: string
  oauth_url: string
  router: NextRouter
  showRedirectUri: boolean
  supportsOAuth: boolean
  queryParams: {
    user_token: string
    code: string
    client_id: string
    secret: string
  }
}

const getAuthorizationUrl = (code: string, token_url: string) => {
  return `/login/authorization?code=${code}&provider_id=5&redirect_uri=https://${window.location.hostname}&oauth_url=${token_url}`
}

export function getCurrentAuthProcess({ showRedirectUri, supportsOAuth, oauth_url, token_url, router, queryParams }: Props) {
  const { user_token, code, client_id, secret } = queryParams

  if (client_id && secret) {
    return () => {
      router.push(`/login/authorization?client_id=${client_id}&secret=${secret}`)
    }
  }

  if (user_token) {
    return async () => {
      AuthToken.remove()

      const { url } = getAuthWindowParams(user_token, oauth_url, showRedirectUri)
      const frameElem = document.createElement('iframe')
      frameElem.style.display = 'none'
      frameElem.src = url as string
      document.body.appendChild(frameElem)
    }
  }

  if (code) {
    return () => {
      AuthToken.remove()
      window.location.href = getAuthorizationUrl(code, token_url)
    }
  }

  if (supportsOAuth) {
    router.replace('/login/oauth')
  }

  return () => {
    router.push('/login/auth-error?reason=bad_login')
  }
}
