'use client'

import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import dynamic from 'next/dynamic'
import { authenticationActions } from '~/store/slices/authentication'
import { getAuthProcessParams, setUUID } from '~/lib/auth'
import { LoginStatusMessage } from '@/LoginStatusMessage'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { getCurrentAuthProcess } from '~/lib/getCurrentAuthProcess'
import { useActionCreators } from '~/store/redux-store'
import { jwtDecode } from 'jwt-decode'

const LoginLayout = dynamic(() => import('~/components/login/LoginLayout'))

const LoginPage = () => {
  setUUID()
  const router = useRouter()
  const { user_token, code, client_id, secret, av } = router.query as {
    user_token: string
    code: string
    client_id: string
    secret: string
    av: string
  }
  const { logout } = useActionCreators(authenticationActions)
  const { token_url, oauth_url, authenticationVariants, bankSpecificFeatures, company } = useSelector(whiteLabelSelector)
  const showRedirectUri = bankSpecificFeatures.includes('showRedirectUri')
  const supportsOAuth = bankSpecificFeatures.includes('supportsOAuth')

  let authenticationVariant: string
  let isCodeJWT = false
  try {
    jwtDecode(code)
    isCodeJWT = true
  } catch (error) {
    console.log(error)
  }

  if (company === 'unicredit' && isCodeJWT) {
    authenticationVariant = 'u24'
  } else {
    authenticationVariant = av
  }

  let token_url_final = token_url
  let oauth_url_final = oauth_url
  const authenticationVariantData = authenticationVariants?.[authenticationVariant]

  if (authenticationVariantData) {
    token_url_final = authenticationVariantData.token_url
    oauth_url_final = authenticationVariantData.oauth_url
  }

  const authProcess = useMemo(
    () =>
      getCurrentAuthProcess({
        supportsOAuth,
        router,
        token_url: token_url_final!,
        oauth_url: oauth_url_final!,
        showRedirectUri,
        queryParams: {
          user_token,
          code,
          client_id,
          secret,
        },
      }),
    [supportsOAuth, oauth_url_final, router, showRedirectUri, token_url_final]
  )

  const onOAuthCallBack = (data: string) => {
    const params = getAuthProcessParams(data)
    window.location.href = `/login/authorization?${params}`
  }

  useLayoutEffect(() => {
    // @ts-ignore
    window.onOAuthCallBack = (data: string) => {
      onOAuthCallBack(data)
    }
    logout()
  })

  useEffect(() => {
    authProcess()
  }, [authProcess])

  return <LoginLayout content={<LoginStatusMessage status="loading" />} />
}

export default LoginPage
